import request from '@/utils/request'


// 查询明星列表
export function listStar(query) {
  return request({
    url: '/star/star/list',
    method: 'get',
    params: query
  })
}

// 查询明星分页
export function pageStar(query) {
  return request({
    url: '/star/star/page',
    method: 'get',
    params: query
  })
}

// 查询明星详细
export function getStar(data) {
  return request({
    url: '/star/star/detail',
    method: 'get',
    params: data
  })
}

// 新增明星
export function addStar(data) {
  return request({
    url: '/star/star/add',
    method: 'post',
    data: data
  })
}

// 修改明星
export function updateStar(data) {
  return request({
    url: '/star/star/edit',
    method: 'post',
    data: data
  })
}

// 删除明星
export function delStar(data) {
  return request({
    url: '/star/star/delete',
    method: 'post',
    data: data
  })
}
